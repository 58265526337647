<template>
  <v-card min-width="300px" height="300px" flat class="rounded-lg">
    <v-card-title class="text-color-title caption font-weight-bold py-1">
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn icon @click="menuClick()">
        <v-icon>mdi-menu-open</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <div class="text-center">
        <div class="text-color-main font-weight-bold mt-4 body-1">
          No. of Users Doing Daily Devotion
        </div>
        <v-sheet>
          <v-sparkline
            :value="values"
            color="primary"
            height="110"
            padding="24"
            stroke-linecap="round"
            smooth
          >
          </v-sparkline>
        </v-sheet>
        <div>
          <span class="ma-2 mr-8">
            <span class="legend -primary mr-2"></span>
            Active
          </span>
          <span class="ma-2 ml-8">
            <span class="legend -secondary mr-2"></span>
            Total
          </span>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    title: "ANALYTICS",
    values: [423, 446, 675, 510, 590, 610, 760]
  }),
  methods: {
    // getData() {
    //   this.citation = {
    //     verse: "John 3:16",
    //     text:
    //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sodales interdum odio non mattis. Etiam placerat elit luctus arcu feugiat tempus. Nulla facilisi. Duis luctus neque vel pretium pretium. Sed."
    //   };
    // },
    menuClick() {
      // alert("click");
    }
  },
  mounted() {
    // this.getData();
  }
};
</script>

<style lang="scss" scoped>
.legend {
  padding-left: 10px;

  &.-primary {
    background: #4ec8eb;
  }

  &.-secondary {
    background: #21558d;
  }
}
</style>
